import * as React from 'react';
import PropTypes from 'prop-types';
import { useDeferredUser } from 'context/user';
import Stack from '@mui/material/Stack';
import Button from 'components/Button';
import ShopButton from 'components/ShopButton';
import UserTypeSwitch from 'components/UserTypeSwitch';
import EventIcon from '@mui/icons-material/EventAvailable';
import ContactIcon from '@mui/icons-material/QuestionAnswer';
import LocationIcon from '@mui/icons-material/LocationOn';
import LocationDialog from 'components/LocationDialog';
import { useLocation } from 'react-router-dom';
import { useBrowser } from 'react-app/context/browser';
import { appData } from 'config/data.cjs';

const DefaultButtonProps = {
	size: 'small',
	variant: 'contained'
};

function AppHeaderButtons(props) {
	const browser = useBrowser();
	const { pathname } = useLocation();
	const [ locationDialogOpen, setLocationDialog ] = React.useState(false);

	const { pages } = appData;

	const { isWidthUp, breakpoint } = browser;
	const smUp = isWidthUp('sm', breakpoint);
	const tabletUp = isWidthUp('tablet', breakpoint);
	const laptopUp = isWidthUp('laptop', breakpoint);
	const lgUp = isWidthUp('lg', breakpoint);
	const [ {isRetail} ] = useDeferredUser();

	const {
		showShopButton = tabletUp,
		showContactButton = laptopUp,
		showLocationButton = false,
		showUserTypeButton = true,
		showConsultationButton = isRetail && lgUp,
		ButtonProps,
		ContactButtonProps,
		ShopButtonProps,
		ConsultationButtonProps,
		LocationButtonProps,
		UserTypeButtonProps,
		...rest
	} = props;

	function handleLocationDialogOpen(event) {
		event.preventDefault();
		setLocationDialog(true);
	}

	function handleLocationDialogClose(event) {
		event.preventDefault();
		setLocationDialog(false);
	}

	return ((smUp || showShopButton || showContactButton || showLocationButton || showUserTypeButton || showConsultationButton) && (
		<Stack direction="row" gap={1} {...rest} >
			{showConsultationButton && (
				<Button
					{...DefaultButtonProps}
					{...ButtonProps}
					{...ConsultationButtonProps}
					href={appData.pages.service2.pathname}
					color={pathname.startsWith(pages.service2.pathname) ? 'primary' : 'default'}
					startIcon={<EventIcon sx={{mt: .125}}/>}
				>
					{appData.pages.service2.title}
				</Button>
			)}
			{showContactButton && (
				<Button
					{...DefaultButtonProps}
					{...ButtonProps}
					{...ContactButtonProps}
					href={pages.contact.pathname}
					color={pathname.startsWith(pages.contact.pathname) ? 'primary' : 'default'}
					startIcon={<ContactIcon sx={{mt: .125}}/>}
				>
					Kontakt
				</Button>
			)}
			{showLocationButton && (
				<>
					<Button
						{...DefaultButtonProps}
						{...ButtonProps}
						{...LocationButtonProps}
						startIcon={<LocationIcon sx={{'&&': {mr: -.75}}}/>}
						onClick={handleLocationDialogOpen}
					>
						Standorte
					</Button>
					<LocationDialog open={locationDialogOpen} onClose={handleLocationDialogClose}/>
				</>
			)}
			{showUserTypeButton && (
				<UserTypeSwitch
					{...DefaultButtonProps}
					{...ButtonProps}
					{...UserTypeButtonProps}
				/>
			)}
			{showShopButton && (
				<ShopButton {...DefaultButtonProps} {...ButtonProps} variant="outlined"/>
			)}
		</Stack>
	));
}

AppHeaderButtons.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	ButtonProps: PropTypes.object,
	showShopButton: PropTypes.bool,
	showContactButton: PropTypes.bool,
	showLocationButton: PropTypes.bool,
	showUserTypeButton: PropTypes.bool,
	showConsultationButton: PropTypes.bool
};

export default React.memo(AppHeaderButtons);
