import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from 'styles';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FacebookIcon from 'react-app/icons/FacebookCircle';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PinterestIcon from '@mui/icons-material/Pinterest';
import YouTubeIcon from '@mui/icons-material/YouTube';
import ShopIcon from '@mui/icons-material/ShoppingCart';
import ContactIcon from '@mui/icons-material/QuestionAnswer';
import MenuItemLink from 'components/MenuItemLink';
import { useDeferredUser } from 'context/user';
import { appData } from 'config/data.cjs';

const useClasses = createClasses((theme) => ({
	root: {},
	icon: {},
	listIcon: {},
	listItem: {
		color: theme.vars.palette.text.primary
	},
	listItemIcon: {
		color: 'inherit',
		minWidth: theme.spacing(4),
		'& > svg': {
			fontSize: '1.375rem'
		}
	}
}), {
	name: 'AppHeaderMenu'
});

const MenuProps = {
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'right'
	},
	transformOrigin: {
		vertical: 'top',
		horizontal: 'right'
	}
};

function AppHeaderMenu(props) {
	const {
		classes: classesProp,
		className,
		open,
		id,
		title,
		onClick,
		onClose,
		IconButtonProps,
		facebook,
		instagram,
		pinterest,
		whatsApp,
		youtube,
		contact,
		shop,
		...rest
	} = props;

	const classes = useClasses(props);

	const [ {isRetail} ] = useDeferredUser();

	return (
		<>
			<IconButton
				color="inherit"
				{...IconButtonProps}
				id={id}
				title={title}
				onClick={onClick}
				className={classes.icon}
			>
				<MoreVertIcon/>
			</IconButton>

			<Menu
				{...MenuProps}
				{...rest}
				open={open}
				onClose={onClose}
				className={clsx(
					classes.root,
					className
				)}
			>
				{shop && (
					<MenuItemLink
						onClick={onClose}
						to={appData.contact[isRetail ? 'shop' : 'profiShop']}
						className={classes.listItem}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<ShopIcon className={classes.listIcon}/>
						</ListItemIcon>
						{isRetail ? 'Online-Shop' : 'Profi-Shop'}
					</MenuItemLink>
				)}

				{facebook && (
					<MenuItemLink
						onClick={onClose}
						to={appData.contact.facebook}
						className={classes.listItem}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<FacebookIcon className={classes.listIcon}/>
						</ListItemIcon>
						Facebook
					</MenuItemLink>
				)}

				{instagram && (
					<MenuItemLink
						onClick={onClose}
						to={appData.contact.instagram}
						className={classes.listItem}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<InstagramIcon className={classes.listIcon}/>
						</ListItemIcon>
						Instagram
					</MenuItemLink>
				)}

				{pinterest && (
					<MenuItemLink
						onClick={onClose}
						to={appData.contact.pinterest}
						className={classes.listItem}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<PinterestIcon className={classes.listIcon}/>
						</ListItemIcon>
						Pinterest
					</MenuItemLink>
				)}

				{youtube && (
					<MenuItemLink
						onClick={onClose}
						to={appData.contact.youtube}
						className={classes.listItem}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<YouTubeIcon className={classes.listIcon}/>
						</ListItemIcon>
						YouTube
					</MenuItemLink>
				)}

				{whatsApp && (
					<MenuItemLink
						onClick={onClose}
						to={appData.contact.whatsApp}
						className={classes.listItem}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<WhatsAppIcon className={classes.listIcon}/>
						</ListItemIcon>
						WhatsApp
					</MenuItemLink>
				)}

				{contact && (
					<MenuItemLink
						onClick={onClose}
						to={appData.pages.contact.pathname}
						className={classes.listItem}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<ContactIcon className={classes.listIcon}/>
						</ListItemIcon>
						Kontakt
					</MenuItemLink>
				)}
			</Menu>
		</>
	);
}

AppHeaderMenu.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	open: PropTypes.bool.isRequired,
	id: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	IconButtonProps: PropTypes.object,
	facebook: PropTypes.bool,
	instagram: PropTypes.bool,
	pinterest: PropTypes.bool,
	contact: PropTypes.bool,
	shop: PropTypes.bool
};

export default React.memo(AppHeaderMenu);
