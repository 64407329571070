import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx } from '../../styles';
import { MenuItem } from '@mui/material';
import Link from '../Link';

const useClasses = createClasses((theme) => ({
	root: {},
	link: {
		padding: theme.spacing(0.75, 2),
		minHeight: theme.spacing(6),
		display: 'flex',
		alignItems:'center',
		justifyContent: 'left',
		width: '100%'
	},
	noGutter: {
		padding: 0
	}
}), {
	name: 'MenuItemLink',
	mergeClassName: false
});

const MenuItemLink = React.forwardRef(function MenuItemLink(props, ref) {
	const {
		classes: classesProp,
		className,
		children,
		to,
		LinkProps,
		LinkComponent = Link,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<MenuItem
			{...rest}
			ref={ref}
			className={clsx(
				classes.root,
				to && classes.noGutter,
				className
			)}
		>
			{to ? (
				<LinkComponent
					underline="none"
					noLinkStyle={false}
					{...LinkProps}
					to={to}
					className={classes.link}
				>
					{children}
				</LinkComponent>
			) : children}
		</MenuItem>
	);
});

MenuItemLink.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	to: PropTypes.string,
	LinkProps: PropTypes.object,
	LinkComponent: PropTypes.elementType
};

export default React.memo(MenuItemLink);
