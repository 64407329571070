import HomeIcon from 'react-app/icons/Home';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SaleIcon from 'react-app/icons/Sale';
import MegaphoneIcon from 'react-app/icons/MegaphoneCircle';
import DiscontinuedIcon from 'react-app/icons/Discontinued';
import ShoppingBasketIcon from 'react-app/icons/ShoppingBasket';
import EyeIcon from 'react-app/icons/Eye';
import HandshakeIcon from 'react-app/icons/Handshake';
import HwLogoIcon from 'react-app/icons/HwLogo';
import EventIcon from '@mui/icons-material/Event';
import CatalogIcon from 'react-app/icons/Catalog';
import ConfigIcon from '@mui/icons-material/Settings';
import HubIcon from '@mui/icons-material/Hub';
import GroupIcon from '@mui/icons-material/Group';
import SchoolIcon from '@mui/icons-material/School';
import GiftIcon from '@mui/icons-material/CardGiftcard';
import HqIcon from 'components/HqIcon';

const IconProps = {
	fontSize: 'small'
};

const drawerMenuIcons = {
	home: <HomeIcon {...IconProps}/>,
	news: <NewReleasesIcon {...IconProps}/>,
	sale: <SaleIcon {...IconProps}/>,
	discontinued: <DiscontinuedIcon {...IconProps}/>,
	assortment: <ShoppingBasketIcon {...IconProps}/>,
	exhibition: <EyeIcon {...IconProps}/>,
	service: <HandshakeIcon {...IconProps}/>,
	events: <EventIcon {...IconProps}/>,
	woodAcademy: <EventIcon {...IconProps}/>,
	catalogs: <CatalogIcon {...IconProps}/>,
	configurators: <ConfigIcon {...IconProps}/>,
	team: <GroupIcon {...IconProps}/>,
	partner: <HubIcon {...IconProps}/>,
	history: <HwLogoIcon {...IconProps}/>,
	anniversary: <MegaphoneIcon {...IconProps}/>,
	giftItems: <GiftIcon {...IconProps}/>,
	jobs: <SchoolIcon {...IconProps}/>,
	hq: <HqIcon {...IconProps}/>
};

export default drawerMenuIcons;
