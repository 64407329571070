/* global WEBPACK_DEV WEBPACK_IS_PUBLIC */

import * as React from 'react';
import loadable from '@loadable/component';
import Stack from '@mui/material/Stack';
import ContactIcons from 'components/ContactIcons';
import { useBrowser } from 'react-app/context/browser';
import { useAuth } from 'context/auth';

const IconsMenu = loadable(() => import('./AppHeaderMenu'));
const UserMenu = loadable(() => import('components/UserMenu'));

const iconsMenuId = 'menu-icons';
const userMenuId = 'menu-user';

function AppHeaderIcons(props) {
	const [auth] = useAuth();
	const { isWidthUp, isWidthDown, breakpoint } =  useBrowser();
	const smUp = isWidthUp('sm', breakpoint);
	const lgUp = isWidthUp('lg', breakpoint);
	const tabletDown = isWidthDown('tablet', breakpoint);

	const timerRef = React.useRef();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [menuId, setMenuId] = React.useState(null);

	function handleMenuOpen(event) {
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		const menuId = event.currentTarget.id;
		setAnchorEl(event.currentTarget);
		setMenuId(menuId);
	}

	function handleMenuClose() {
		setAnchorEl(null);
		setMenuId(null);
	}

	return (
		<Stack
			flexWrap="nowrap"
			flexDirection="row"
			justifyContent="flex-end"
			marginRight={{xs: -.25, sm: .25}}
			{...props}
		>
			<ContactIcons
				facebook
				instagram
				youtube={smUp}
				pinterest={smUp}
				shop={tabletDown}
				consultation={false}
				contact={false}
				whatsApp={false}
				defaultAppearance
			/>

			{auth.isAuthenticated && (
				<UserMenu
					anchorEl={anchorEl}
					title="Benutzer-Menu"
					id={userMenuId}
					open={menuId === userMenuId}
					onClick={handleMenuOpen}
					onClose={handleMenuClose}
				/>
			)}

			{!smUp && (
				<IconsMenu
					anchorEl={anchorEl}
					title="Mehr"
					id={iconsMenuId}
					open={menuId === iconsMenuId}
					onClick={handleMenuOpen}
					onClose={handleMenuClose}
					pinterest
					youtube
					contact
				/>
			)}
		</Stack>
	);
}

export default React.memo(AppHeaderIcons);
