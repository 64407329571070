import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Button from 'components/Button';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import { usePageData } from 'react-app/context/page-data';
import { useDeferredUser } from 'context/user';

function UserTypeSwitch(props) {
	const [ {userType}, {setUserType} ] = useDeferredUser();
	const [ pageData = {} ] = usePageData();

	const {
		children,
		userType: userTypeProp = userType,
		href,
		onClick,
		...rest
	} = props;

	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const userTypeParam = queryParams.get('ut') || queryParams.get('userType');

	const isRetail = userTypeProp === 'retail';
	const buttonTitle = isRetail ? 'Grosshandel' : 'Einzelhandel';
	const isDisabled = Boolean(userTypeParam || pageData.userType);

	function handleClick(event) {
		event.preventDefault();
		setUserType(isRetail ? 'wholesale' : 'retail');
		if (typeof onClick === 'function') {
			onClick(event);
		}
	}

	return (
		<span title={isDisabled ? `Diese Seite ist im ${buttonTitle} nicht verfügbar` : `zum ${buttonTitle} wechseln`}>
			<Button
				onClick={handleClick}
				startIcon={isRetail ? <BusinessIcon/> : <StoreIcon/>}
				{...rest}
				disabled={isDisabled}
			>
				{children || buttonTitle}
			</Button>
		</span>
	);
}

UserTypeSwitch.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	href: PropTypes.string,
	userType: PropTypes.oneOf(['retail', 'wholesale'])
};

export default React.memo(UserTypeSwitch);
